import { useState, useEffect } from "react";
import "../assets/css/footer/footer.css";

const Footer = () => {
  const [currentDate, setCurrentDate] = useState()

  useEffect(() => {
    const getDate = () => {
      const date = new Date().getFullYear()
      setCurrentDate(date)
    }
    getDate()
  }, [])

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>Comprehensive Staffing Resources Ltd</h1>
            <p>
              Our expertise will drive all aspects of your <br></br> business
              operations to outstanding heights.<br></br> We strive to keep our
              services qualitative <br></br> and accurate, making sure to
              deliver on <br></br> time.
            </p>
          </div>

          <div className="col">
            <h1>services</h1>
            <div className="services-list">
              <p>Accounting Services</p>
              <p>Graphic Design</p>
              <p>Software Development</p>
              <p>Digital Marketing</p>
              <p>Tech Support</p>
            </div>
          </div>

          <div className="col">
            <h1>Our Products</h1>
            <div className="products-links">
              <a href="https://jobmatch.csrlimited.com/">Job Match</a>
              <a href="https://trainings.csrlimited.com/">Training Center</a>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-footer">
        <div className="container">
          <div className="copyright-text">
            <p>&copy; {currentDate} Copyright, All Rights Reserved</p>
          </div>

          <div className="menu-links">
            <a href="/privacy-policy/">privacy policy</a>
            <a href="/terms-of-service/"> Terms of Service</a>
          </div>

          <div className="social-media-links">
            <a href="https://www.facebook.com/profile.php?id=100086812094253&mibextid=LQQJ4d">
              <i class="fa-brands fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/csr.rw/">
              <i class="fa-brands fa-square-instagram"></i>
            </a>
            <a href="https://youtube.com/@comprehensivestaffingresources">
              <i class="fa-brands fa-youtube"></i>
            </a>
            <a href="https://twitter.com/csr_rw">
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a href="https://www.linkedin.com/company/comprehensive-staffing-resources-ltd/">
              <i class="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>

      </div>

    </footer>
  );
};

export default Footer;
