import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../components/footer';
import { FaFolderOpen, FaBars } from "react-icons/fa6";
import {
    FaUser,
    FaCalendarDay,
    FaAddressBook,
    FaHeadset,
    FaCalculator,
    FaPercentage,
    FaTable,
    FaFileInvoiceDollar
} from "react-icons/fa";

// styles
import '../../assets/css/main/main.css';
import '../../assets/css/bogs/blogs-details.css';
import { Header } from '../../components/header';

const BlogsDetails = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [blogDetails, setBlogDetails] = useState()
    const { blogSlug } = useParams()
    const apiUrl = "https://csr-xhww.onrender.com/api"
    useEffect(() => {
        const getBlog = async () => {
            try {
                const res = await fetch(`${apiUrl}/blog/details/${blogSlug}`)
                const data = await res.json()
                const blog = {
                    ...data,
                    // getting blog date
                    blogDate: new Date(data.date_created).toLocaleDateString()
                }
                setBlogDetails(blog)
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.log(error)
            }
        }
        getBlog()
    }, [])

    return (
        <>
            <Header specialClass={'white-pages'} />
            <div className="single-blog-page">

                <div className="container">

                    {
                        isLoading ? (
                            <div className="blog-detail-loader">
                                <div className="content">
                                    <div className="featured-image loader"></div>
                                    <div className="title loader"></div>
                                    <div className="metas">
                                        <div className="meta loader"></div>
                                        <div className="meta loader"></div>
                                        <div className="meta loader"></div>
                                    </div>

                                    <div className="text-content">
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                        <div className="text loader"></div>
                                    </div>
                                </div>
                                <div className="services">
                                    <div className="title loader"></div>
                                    <div className="service">
                                        <div className="icon loader"></div>
                                        <div className="text loader"></div>
                                    </div>
                                    <div className="service">
                                        <div className="icon loader"></div>
                                        <div className="text loader"></div>
                                    </div>
                                    <div className="service">
                                        <div className="icon loader"></div>
                                        <div className="text loader"></div>
                                    </div>
                                    <div className="service">
                                        <div className="icon loader"></div>
                                        <div className="text loader"></div>
                                    </div>
                                    <div className="service">
                                        <div className="icon loader"></div>
                                        <div className="text loader"></div>
                                    </div>
                                    <div className="service">
                                        <div className="icon loader"></div>
                                        <div className="text loader"></div>
                                    </div>
                                    <div className="service">
                                        <div className="icon loader"></div>
                                        <div className="text loader"></div>
                                    </div>
                                    <div className="service">
                                        <div className="icon loader"></div>
                                        <div className="text loader"></div>
                                    </div>
                                </div>
                            </div>
                        ) :
                            <div className="row">
                                <div className="col col-1">
                                    <img className='featured-image' src={blogDetails?.featured_image} alt="book-keeping" />
                                    <h1 className='title'>{blogDetails?.title}</h1>
                                    <div className="blog-details">
                                        <div className="detail">
                                            <div className="detail-icon">
                                                <FaFolderOpen />
                                            </div>
                                            <p>Resourcing staffing</p>
                                        </div>
                                        <div className="detail">
                                            <div className="detail-icon">
                                                <FaUser />
                                            </div>
                                            {blogDetails?.authors.map((author) => (
                                                <p>{author.first_name} {author.last_name}</p>
                                            ))}
                                        </div>
                                        <div className="detail">
                                            <div className="detail-icon">
                                                <FaCalendarDay />
                                            </div>
                                            <p>{blogDetails?.blogDate}</p>
                                        </div>
                                    </div>
                                    <p>{blogDetails?.short_description}</p>
                                    <p dangerouslySetInnerHTML={{ __html: blogDetails?.content }}></p>
                                </div>
                                <div className="col col-2">
                                    <div className="our-services">
                                        <h2>Our Services</h2>
                                        <div className="service">
                                            <div className="service-icon">
                                                <FaAddressBook />
                                            </div>
                                            <p>Bookkeeping and <br /> Outsourcing of Staff</p>
                                        </div>
                                        <div className="service">
                                            <div className="service-icon">
                                                <FaHeadset />
                                            </div>
                                            <p>Customer Support <br /> services</p>
                                        </div>
                                        <div className="service">
                                            <div className="service-icon">
                                                <FaCalculator />
                                            </div>
                                            <p>Financial analysis <br /> and calculations</p>
                                        </div>
                                        <div className="service">
                                            <div className="service-icon">
                                                <FaPercentage />
                                            </div>
                                            <p>Non-deposit-taking <br /> microfinance</p>
                                        </div>
                                        <div className="service">
                                            <div className="service-icon">
                                                <FaTable />
                                            </div>
                                            <p>Data Entry <br /> Service</p>
                                        </div>
                                        <div className="service">
                                            <div className="service-icon">
                                                <FaFileInvoiceDollar />
                                            </div>
                                            <p>Payroll Management <br /> and Funding</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BlogsDetails
