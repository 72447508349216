import React, { useState } from "react";
import "../../src/assets/css/ourteam/ourteam.css";
import CEO from "../../src/assets/img/CEO.jpeg";
import henry from "../../src/assets/img/henry.jpg";
import tinah from "../../src/assets/img/tinah.jpg";
import alphonse from "../../src/assets/img/alphonse.jpg";
import patient from "../../src/assets/img/patient.jpeg";
import charles from "../../src/assets/img/charles.jpg";
import jayp from "../../src/assets/img/jayp.jpg";
import victor from "../../src/assets/img/victor.jpg";

const OurTeam = () => {
  const [popup, setPopup] = useState({ visible: false, content: {} });

  const handlePopupOpen = (content) => {
    setPopup({ visible: true, content });
  };

  const handlePopupClose = () => {
    setPopup({ visible: false, content: {} });
  };

  return (
    <div className="our_team">
      {popup.visible && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handlePopupClose}>
              &times;
            </span>
            <div className="popup-info">
              <div className="col">
                <img  className ="popup-image" src={popup.content.image} alt="" />
                <div className="pic_title">
                  <h3>{popup.content.title}</h3>
                  <p>{popup.content.position}</p>
                </div>
              </div>
              <p>{popup.content.description}</p>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="title">
          <h1>Our Team</h1>
          <p>People behind the good and amazing services that we give to clients.</p>
        </div>
        <div className="people">
          <div className="first_row">
            <div className="img_text">
              <div className="col">
                <img src={CEO} alt="" />
                <div className="pic_title">
                  <h3>Godwin Feh</h3>
                  <p>Founder & CEO</p>
                </div>
              </div>
              <div
                className="text"
                onClick={() =>
                  handlePopupOpen({
                    image: CEO,
                    title: "Godwin Feh",
                    position: "Founder & CEO",
                    description:
                      "Godwin Feh, with more than two decades of experience as a patient-focused health care leader with proven expertise in acute care, staff development and family advocacy, founded Cohesive Healthcare in 2016.  As Cohesive Healthcare has grown over the years, Feh continues expanding the activity of Cohesive; after visiting Rwanda, he found that Rwanda is a good place for a business. This makes him register Comprehensive Staffing Resource Ltd in June 2022, in the way of expanding and providing the work and solutions. Before founding Cohesive Healthcare, Comprehensive Staffing Resources Ltd Feh served in leadership positions in several long-term acute care hospitals and critical care hospitals. During his tenure at various healthcare facilities, Feh was widely recognized as an excellent care provider, patient advocate and hospital administrator.  Feh received his Bachelor’s in Science in Nursing and his Master’s in Business Administration from St. Gregory University. He is a licensed registered nurse and is ACLS and BLS certified."
                  })
                }
              >
                <p>Godwin Feh, with more than two decades of experience as a patient-focused health care leader with proven expertise in acute care...</p>
              </div>
            </div>
            <div className="img_text">
              <div className="col">
                <img src={victor} alt="" />
                <div className="pic_title">
                  <h3>Victor Feh</h3>
                  <p>Managing Director</p>
                </div>
              </div>
              <div
                className="text"
                onClick={() =>
                  handlePopupOpen({
                    image: victor,
                    title: "Victor Feh",
                    position: "Managing Director",
                    description:
                      "Victor Feh's extensive career includes establishing and building start-up companies, especially in the service industry. The focus now is to bring Comprehensive Staffing Resources Ltd towards goal realization by providing solutions to Rwandan start-up companies abroad. As a Managing Director, Victor Feh, provides inspiration, motivation and guidance to leaders and managers in the company, by directing the company’s operation to make sure it achieves its objectives effectively and efficiently. Victor Feh combines a background in BPhil in business philosophy from the Urbaniana University Rome, Msc in Transport and Logistics, LLB in Law-specialized in Business Law, and a Diploma in Legal Practice and Development. Victor Feh looks forward to unifying these diversified backgrounds to make Comprehensive Staffing Resources your trusted partner."
                  })
                }
              >
                <p>Victor Feh's extensive career includes establishing and building start-up companies, especially in the service industry...</p>
              </div>
            </div>

            <div className="img_text">
              <div className="col">
                <img src={jayp} alt="" />
                <div className="pic_title">
                  <h3>jean paul</h3>
                  <p>chief of finance</p>
                </div>
              </div>
              <div
                className="text"
                onClick={() =>
                  handlePopupOpen({
                    image: jayp,
                    title: "Jean paul",
                    position: "chief of finance",
                    description:
                      "As a finance professional with over 9 years of experience, He has a proven track record of excelling in financial management, planning, and the management of business operations. Throughout his career, he has engaged his skills in various sectors including the public sector and the manufacturing of goods and services. In his most recent role as the head of the finance department for a company that deals with transport ticketing and processing, he successfully oversaw the department's cashflow projections and management, provided financial analysis and advice to the board, and developed finance standards, policies, procedures, and systems. Additionally, he implemented appropriate action plans to ensure sound financial management, and advised management on investing decisions. As a leader, he possess the ability to drive change by constantly challenging and improving existing processes and systems. jean paul's focus is to work with a dynamic organization that requires creative thinking, problem-solving, and efficiency within the finance department. He has excited to bring his experience and skills to a new opportunity and contribute to the success of the organization."
                  })
                }
              >
                <p>As a finance professional with over 9 years of experience, I have a proven track record of excelling in financial management, planning, and the management...</p>
              </div>
            </div>
          
          </div>


          <div className="first_row">
            <div className="img_text">
              <div className="col">
                <img src={patient} alt="" />
                <div className="pic_title">
                  <h3>Patient Karenzi</h3>
                  <p>Chief of operations Officer</p>
                </div>
              </div>
              <div
                className="text"
                onClick={() =>
                  handlePopupOpen({
                    image: patient,
                    title: "Patient Karenzi",
                    position: "chief of operations officer",
                    description:
                      "Patient KARENZI is a Chief Operations Officer of Comprehensive Staffing Resources Ltd; alongside with his duties, he manages financial and administration activities, a business plan, timeline, and budget to perform economic projects and develop overall goals for the company (marketing, finance, and administration departments.) Identify and resolve financial and administration"
                  })
                }
              >
                <p>Patient KARENZI is a Chief Operations Officer of Comprehensive Staffing Resources Ltd; manages financial and administration activities...</p>
              </div>
            </div>
            <div className="img_text">
              <div className="col">
                <img src={alphonse} alt="" />
                <div className="pic_title">
                  <h3>Sibomana Alphonse</h3>
                  <p>Director Of Software Development</p>
                </div>
              </div>
              <div
                className="text"
                onClick={() =>
                  handlePopupOpen({
                    image: alphonse,
                    title: "Sibomana Alphonse",
                    position: "Director Of Software development",
                    description:
                      "Alphonse is the Director Of Software Development at CSR. Alongside managerial duties, he is a senior software engineer, where he leads a team of 7+ junior developers. The department’s goals is empowering businesses with software solutions that make them stand out from their competitors."
                  })
                }
              >
                <p>Alphonse is the senior manager for software development department at CSR. Alongside managerial duties....</p>
              </div>
            </div>

            <div className="img_text">
              <div className="col">
                <img src={tinah} alt="" />
                <div className="pic_title">
                  <h3>Tinah</h3>
                  <p>Director Of Digital Marketing</p>
                </div>
              </div>
              <div
                className="text"
                onClick={() =>
                  handlePopupOpen({
                    image: tinah,
                    title: "Tinah",
                    position: "Director Of Digital Mareting",
                    description:
                      "Self-motivated and experienced marketing and communications professional with over 6 years of industry experience. She have developed and implemented successful communication plans, campaigns, and events for various audiences, including internal, external, and digital platforms. Her work has helped her to promote brands, products, and services in different sectors, including CIMERWA, Unilever Rwanda, Intare Conference Arena, GIZ Rwanda, Gorilla Games, Toyota Rwanda, Babyl Rwanda, Africa Improved Foods, and Jabalee Sports, to name a few. Christine's extensive experience in the field has been gained from working with top Marketing and Communications agencies such as Quake Ltd and Brand Speak Global. Through these experiences, She have honed her skills in communication strategy, brand management, social media marketing, and event planning, among other areas. Currently, She is working as the Senior Digital Marketing and Communications Manager at Comprehensive Staffing Resources, where She helps clients achieve their goals through social media marketing. Her passion for the industry drives her to stay updated with the latest trends and technology to ensure that clients are always ahead of their competition. she is committed to delivering quality results, meeting deadlines, and exceeding expectations. She believe in the power of collaboration and teamwork and approach every project with an open mind, taking into consideration the unique needs of each client. Christine is excited to continue growing her skills and experience in the industry and look forward to creating meaningful connections with new clients and colleagues."
                  })
                }
              >
                <p>Self-motivated and experienced in disciplines such as marketing and communications. I develop and implement communications plans...</p>
              </div>
            </div>
           
          </div>

          <div className="first_row">
            <div className="img_text">
              <div className="col">
                <img src={charles} alt="" />
                <div className="pic_title">
                  <h3>Charles Ruzindana</h3>
                  <p>Director Of Acounting</p>
                </div>
              </div>
              <div
                className="text"
                onClick={() =>
                  handlePopupOpen({
                    image: charles,
                    title: "Charles Ruzindana",
                    position: "Director Of Accounting",
                    description:
                      "Charles is the Director Of Accounting at CSR LTD. His strong project management skills include leadership, negotiation, team building, problem-solving, finance, accounting, human resource management, and the ability to prepare staff performance appraisals and compute local taxes for organizations. He also has experience in project planning, execution, organizing staff-wide meetings and assigning action items, as well as monitoring and controlling company-wide events to achieve organizational goals. He holds a Bachelor's degree in Commerce, specializing in management from UR-CBE. His self-motivation and expertise in various areas makes him a valuable asset to CSR LTD and any organization he works with. He is dedicated to achieving the expected outcomes and contributing to the success of the organization."
                  })
                }
              >
                <p>Charles is the Director of Accounting at CSR LTD. His strong project management skills include leadership,</p>
              </div>
            </div>
            

            <div className="img_text">
              <div className="col">
                <img src={henry} alt="" className="popup-image" />
                <div className="pic_title">
                  <h3>Henry Peter Ngoga</h3>
                  <p>Director Of Graphic design</p>
                </div>
              </div>
              <div
                className="text"
                onClick={() =>
                  handlePopupOpen({
                    image: henry,
                    title: "Henry Peter Ngoga",
                    position: "Director Of Graphic Design",
                    description:
                      "Henry is the Director if Graphic Design at CSR LTD. He have always had a passion for making visual designs, and started honing his craft at a young age. As the team leader of a team of designers providing graphic design services to our clients, where we turn your ideas into images, He honored to offer my 3+ years of experience to CSR."
                  })
                }
              >
                <p>Henry is the Director Of Graphic Design at CSR LTD. He have always had a passion for making visual designs, and started honing..</p>
              </div>
            </div>
            <div className="img_text"></div>
          
          </div>


         

        
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
