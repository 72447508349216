import React, { useState, useEffect } from "react";
import logo from '../pages/home/csr-logo.png';
import { Link } from "react-router-dom";
import "../assets/css/main/main.css";
import "../assets/css/main/header.css";

import { FaAngleDown } from "react-icons/fa6";


const Header = ({ specialClass }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 100) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        if (specialClass === 'white-pages') {
            setIsScrolled(true);
        }
    })
    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className={`header ${isScrolled || isMenuOpen ? 'scrolled' : ''} ${specialClass ? `scrolled ${specialClass}` : ''}`}>
            <div className="notification">
                <div className="trust">
                    <a href="/blogs/">We are reintroducing our blog. <span>Click here</span> to read the latest</a>
                </div>
            </div>
            <div className="trust">
                <nav className="nav-header">
                    <Link to={'/'} className="logo">
                        <img src={isScrolled || isMenuOpen ? '/assets/img/home/logo-blue.png' : logo} alt="CSR Logo" />
                    </Link>
                    <div className={`nav-menu ${isMenuOpen ? 'responsive' : ''}`}>
                        <div className="links">
                            <ul>
                                <li>
                                    <Link className="link" to="/">Home</Link>
                                </li>
                                <li>
                                    <Link className="link" to="/about-us/">About Us</Link>
                                </li>
                                <li className="dropdown">
                                    Services <span><FaAngleDown /></span>
                                    <ul className="dropdown-menu">
                                        <li>

                                            <a href="/finance-acc/">
                                                <i className="fas fa-chart-line"></i>
                                                <h3> <Link to="/accounting/">Accounting Services</Link></h3>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/graphic-design/">
                                                <i className="fas fa-pencil-ruler"></i>
                                                <h3><Link className="link" to="/graphic-design">Graphic Design</Link></h3>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/software-dev/">
                                                <i className="fas fa-code"></i>
                                                <h3><Link className="link" to="/software-dev/">Software Development</Link></h3>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/social-media/">
                                                <i className="fas fa-share-alt"></i>
                                                <h3><Link className="link" to="/digital-marketing/">Digital Marketing</Link></h3>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/tech-support/">
                                                <i className="fas fa-laptop"></i>
                                                <h3><Link className="link" to="/tech-support/">Tech Support</Link></h3>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link className="link" to={'http://trainings.csrlimited.com/'}>Training center</Link>
                                </li>
                                <li>
                                    <Link className="link" to={'/blogs/'}>Our blog</Link>
                                </li>
                            </ul>
                            <a href="/contact/" className="action-button">
                                <button className={isScrolled ? "white-bg" : "blue-bg"}>Contact</button>
                            </a>
                        </div>
                    </div>
                    <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

const SecondHeader = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 100) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className={`second-header ${isScrolled || isMenuOpen ? 'scrolled' : ''}`}>
            <div className="trust">
                <nav className="nav-header">
                    <div className="logo">
                        <img src='/assets/img/home/logo-blue.png' alt="CSR Logo" />
                    </div>
                    <div className={`nav-menu ${isMenuOpen ? 'responsive' : ''}`}>
                        <div className="links">
                            <ul>
                                <li><Link className="second-link" to="/">Home</Link></li>
                                <li><Link className="second-link" to="/aboutuspage">About Us</Link></li>
                                <li className="dropdown">
                                    <span>Services <i className="fa fa-angle-down"></i></span>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a href="/finance-acc/">
                                                <i className="fas fa-chart-line"></i>
                                                <h3> <Link to="/accounting">Accounting Services</Link></h3>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/graphic-design/">
                                                <i className="fas fa-pencil-ruler"></i>
                                                <h3><Link className="link" to="/graphic-design">Graphic Design</Link></h3>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/software-dev/">
                                                <i className="fas fa-code"></i>
                                                <h3><Link className="link" to="/software-dev">Software Development</Link></h3>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/social-media/">
                                                <i className="fas fa-share-alt"></i>
                                                <h3><Link className="link" to="/social-media">Digital Marketing</Link></h3>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/tech-support/">
                                                <i className="fas fa-laptop"></i>
                                                <h3><Link className="link" to="/techsupport">Tech Support</Link></h3>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li><Link className="second-link" to="/comingsoon">Gallery</Link></li>
                                <li><Link className="second-link" to="/">News Room</Link></li>
                            </ul>


                            <Link to="/contact">
                                <button className="white-bg">
                                    Get in touch

                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export { Header, SecondHeader };




