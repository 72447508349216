// get date like this: 2024 -09-02T08: 54:08.046254Z and retun  human redabe date and time (to minutes)

export const formattedDateTime = (date) => {
    if (!date) {
        return 'Invalid date';
    }
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = `${dateObj.getMonth() + 1}`.padStart(2, '0');
    const day = `${dateObj.getDate()}`.padStart(2, '0');
    const hours = `${dateObj.getHours()}`.padStart(2, '0');
    const minutes = `${dateObj.getMinutes()}`.padStart(2, '0');
    const seconds = `${dateObj.getSeconds()}`.padStart(2, '0');

    return `${year}-${month}-${day}`;
}