import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import '../../assets/css/bogs/blogs.css'
import { slicedText } from '../../utils/slicedText'
import { formattedDateTime } from '../../utils/formatedDateTime'
import { BiCalendar, BiUser } from 'react-icons/bi'
import { Header } from '../../components/header'

const BlogsPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [blogs, setBlogs] = useState([])
  const apiUrl = 'https://csr-xhww.onrender.com/api'
  const [error, setError] = useState("")

  useEffect(() => {
    setError("")
    const getBlogs = async () => {
      console.log("Getting blogs")
      try {
        const response = await axios.get(`${apiUrl}/blog/all/`)
        if (response.status === 200) {
          setBlogs(response.data)
          console.log('got blogs', response.data)
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)

        if (error.response) {
          setError(error.response.data.message || error.response.data.error || 'Error while getting blogs')
        } else {
          setError('Error while getting blogs. Check your network settings')
        }
      }
    }
    getBlogs()
  }, [])
  return (
    <div className="blog-archive-page">
      <Header specialClass={'white-pages'} />
      <Helmet>
        <title>Blogs | CSR Limited</title>
      </Helmet>
      {
        isLoading ?
          <div className="blogs-loader container">
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
          </div>

          : <div className='blogs-archive container'>
            {
              error && <div className='error'>{error}</div>
            }
            {
              blogs && blogs.map((blog, index) => (
                <Link className='blog-card' key={index} to={`/blogs/details/${blog.slug}/`}>
                  <img src={blog.featured_image} alt="" />
                  <div className="blog-info">
                    <div className="blog-meta">
                      <div className="meta">
                        <BiUser size={18} />
                        {blog.authors.map((author, index) => (
                          <span key={index}>{author.first_name} {author.last_name}</span>
                        ))}
                      </div>
                      <div className='meta'>
                        <BiCalendar size={18} />
                        <span>{formattedDateTime(blog.date_created)}</span>
                      </div>
                      <div className="authors">

                      </div>
                    </div>
                    <h4 className='blog-title'>{slicedText(blog.title, 48)}</h4>

                    <p
                      dangerouslySetInnerHTML={{ __html: slicedText(blog.content, 148) }}
                      className='description'>
                    </p>
                  </div>
                </Link>
              ))
            }
          </div>
      }
    </div>
  )
}

export default BlogsPage
