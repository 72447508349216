import React from 'react'

const NotFound = () => {
  return (
    <div>
      <h2>Oops!!! This page does not exist</h2>
      <a href="/">Go to home</a>
    </div>
  )
}

export default NotFound
