import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/footer";
import { Header } from "../../components/header";
import "../../assets/css/pages/home/home.css";
import "../../assets/css/main/main.css";
import business from "./business-operations.jpg";
import cost from "./cost-savings.jpg";
import outsourcing from "./outsourcing.jpg";
import { Link } from "react-router-dom";
import NewSubscriptionForm from "../../components/forms/NewSubscriptionForm";
import { Cancel01Icon } from "hugeicons-react"


const HomePage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupClosed, setPopupClosed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (popupClosed) return;
      const servicesSection = document.querySelector(".services");
      if (servicesSection) {
        const rect = servicesSection.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          setShowPopup(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [popupClosed]);

  const handlePopupClose = () => {
    setShowPopup(false);
    setPopupClosed(true);
  };

  return (
    <div className="all-content">
      <Header />
      <Helmet>
        <title>Home | CSR Limited</title>
      </Helmet>
      <section className="hero-section">
        <div className="trust">
          <div className="big-text">
            <h2>TRUSTED PARTNER</h2>
            <h1>Our Clients, Our Priority</h1>
            <p>
              CSR Ltd is dedicated to easing your business operations with
              cost-effective solutions ranging from accounting services to
              software development, digital marketing, Technical Support, and
              graphic designs
            </p>
            <div>

              <Link className="other-link" to="/contact/">
                <button>
                  Get in touch
                  <i className="fa-solid fa-angles-right"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="trust">
        <div className="hero-bottom">
          <div className="hero-content">
            <i className="fas fa-chart-line"></i>
            <h1>
              <Link className="other-link" to="/accounting/">
                Accounting <br />
                services
              </Link>
            </h1>
          </div>

          <div className="hero-content">
            <i className="fas fa-pencil-ruler"></i>
            <h1>
              <Link className="other-link" to="/graphic-design/">
                Graphic <br />
                Design
              </Link>
            </h1>
          </div>

          <div className="hero-content">
            <i className="fas fa-code"></i>
            <h1>
              <Link className="other-link" to="/software-dev/">
                Software <br />
                Development
              </Link>
            </h1>
          </div>

          <div className="hero-content">
            <i className="fas fa-share-alt"></i>
            <h1>
              <Link className="other-link" to="/digital-marketing/">
                Digital <br />
                Marketing
              </Link>
            </h1>
          </div>

          <div className="hero-content">
            <i className="fas fa-laptop"></i>
            <h1>
              <Link className="other-link" to="/tech-support/">
                Tech <br />
                Support
              </Link>
            </h1>
          </div>
        </div>
      </div>
      <section className="trust">
        <section className="why-us">
          <div className="why-content">
            <div className="why-text">
              <div>
                <h1>Why Us?</h1>
              </div>
              <h2>Business Operations</h2>
              <p>
                Our services come with guaranteed quality at a competitive
                price-giving our clients the complete advantage of business
                process outsourcing. Most of our clients can achieve cost
                savings of at least 50%.
              </p>
              <Link className="other-link" to="/contact/">
                Get in touch with us
              </Link>
            </div>
            <div className="why-img">
              <img src={business} alt="" />
            </div>
          </div>

          <div className="why-content">
            <div className="why-img">
              <img src={cost} alt="" />
            </div>
            <div className="why-text">
              <h2>Significant Cost Savings Guaranteed</h2>
              <p>
                Our services come with guaranteed quality at a competitive
                price-giving our clients the complete advantage of business
                process outsourcing. Most of our clients can achieve cost
                savings of at least 50%.
              </p>
              <Link className="other-link" to="/contact/">
                Get in touch with us
              </Link>
            </div>


          </div>

          <div className="why-content">
            <div className="why-text">
              <h2>A Global Outsourcing Firm</h2>
              <p>
                Our services come with guaranteed quality at a competitive
                price-giving our clients the complete advantage of business
                process outsourcing. Most of our clients can achieve cost
                savings of at least 50%.
              </p>
              <Link className="other-link" to="/contact/">
                Get in touch with us
              </Link>
            </div>
            <div className="why-img">
              <img src={outsourcing} alt="" />
            </div>
          </div>
        </section>

        <section className="subscribe">
          <div>
            <h1>
              Don't fall behind grow your <br />
              knowledge
            </h1>

            <p>
              Subscribe to our blog for industry insights, expert <br />{" "}
              analysis, and actionable tips.
            </p>
          </div>
          <a href="">
            <button>
              Click here to subscribe
              <i className="fa-solid fa-angles-right"></i>
            </button>
          </a>
        </section>

        <section className="services">
          <div className="services-header">
            <h1>Our Services</h1>
            <h2>
              Welcome to our services. For any inquiries, feel free to contact
              us
            </h2>
          </div>
          <div className="service-content">
            <div className="service-text">
              <div className="text-header">
                <i className="fas fa-chart-line"></i>
                <h1>
                  Accounting <br />
                  Services
                </h1>
              </div>
              <p>
                Professional financial management and bookkeeping to keep your
                business finances organized and up-to-date.
              </p>
            </div>

            <div className="service-text">
              <div className="text-header">
                <i className="fas fa-pencil-ruler"></i>
                <h1>
                  Graphic
                  <br /> Design
                </h1>
              </div>
              <p>
                We offer comprehensive branding services that encompass
                everything from logo design to brand strategy development
              </p>
            </div>

            <div className="service-text">
              <div className="text-header">
                <i className="fas fa-code"></i>
                <h1>
                  Software <br />
                  Development
                </h1>
              </div>
              <p>
                Comprehensive software development services, including design,
                coding, testing, and debugging to bring your ideas to life.
              </p>
            </div>

            <div className="service-text">
              <div className="text-header">
                <i className="fas fa-share-alt"></i>
                <h1>
                  Digital <br />
                  Marketing
                </h1>
              </div>
              <p>
                Strategic digital marketing services to reach and engage your
                target audience, drive sales and grow your business through
                digital channels.
              </p>
            </div>

            <div className="service-text">
              <div className="text-header">
                <i className="fas fa-laptop"></i>
                <h1>
                  Tech <br />
                  Support
                </h1>
              </div>
              <p>
                We provide a range of IT solutions, including speaking to
                customers, testing, and evaluating new technologies to ensure
                optimal implementation and meet your business needs.
              </p>
            </div>
          </div>
          {showPopup && (
            <div className="popup">
              <div className="popup-content">
                <span className="close" onClick={handlePopupClose}>
                  <Cancel01Icon />
                </span>
                <NewSubscriptionForm />
              </div>
            </div>
          )}
        </section>
        <section className="let-partner">
          <h1>Let Us Partner With You</h1>
          <p>our services are professionally delivered around the world </p>


          <Link className="other-link" to="/contact">
            <button>
              Get in touch
              <i className="fa-solid fa-angles-right"></i>
            </button>
          </Link>
        </section>

        <section className="our-partners">
          <h1>Our Partners</h1>

          <div className="image-container">
            <img src="/assets/img/home/partner1.jpeg" alt="" />
            <img src="/assets/img/home/partner2.jpeg" alt="" />
            <img src="/assets/img/home/partner3.jpeg" alt="" />
            <img src="/assets/img/home/partner4.jpeg" alt="" />
            <img src="/assets/img/home/partner5.jpeg" alt="" />
          </div>
        </section>

        <section className="contacts">
          <div>
            <h1>Get in touch with us</h1>
            <p>
              Thank you for your interest in contacting CSR Ltd, please reach us
              at the below contacts we will be happy to discuss this with you.
            </p>
          </div>

          <div className="location-form">
            <div className="location">
              <div className="location-text">
                <div className="location-header">
                  <i className="fa fa-map-marker"></i>
                  <h1>Rwanda Office</h1>
                </div>
                <p>Gisimenti</p>
                <p>37 KG 175 Street, Kigali</p>
              </div>

              <div className="location-text">
                <div className="location-header">
                  <i className="fa fa-map-marker"></i>
                  <h1>USA Office</h1>
                </div>
                <p>2510 E Independence st</p>
                <p>816 E. Federal Shawnee,</p>
                <p>OK 74804</p>
              </div>

              <div className="location-text">
                <div className="location-header">
                  <i className="fa fa-envelope"></i>
                  <h1>Email Us</h1>
                </div>
                <p>info@csrlimite.rw</p>
                <p>info@csrlimite.com</p>
              </div>

              <div className="location-text">
                <div className="location-header">
                  <i className="fa fa-phone"></i>
                  <h1>Call Us</h1>
                </div>
                <p>Phone: +250791902159</p>
                <p>Phone: 405.777.4097</p>
              </div>
            </div>
            <div>
              <form action="">
                <label htmlFor="">
                  <input type="text" placeholder="Full name" />
                </label>
                <label htmlFor="">
                  <input type="text" placeholder="Email address" />
                </label>
                <label htmlFor="">
                  <input
                    type="text"
                    placeholder=" Phone number with country code"
                  />
                </label>

                <label htmlFor="">
                  <input
                    className="your-message"
                    type="text"
                    placeholder=" Your message"
                  />
                </label>

                <button>Send message</button>
              </form>
            </div>
          </div>
        </section>
      </section>
      <div className="trust">
        <iframe
          className="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d463.7506059040496!2d30.10970384127117!3d-1.9612422066287096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca6545e70668d%3A0xa60e0cff908dca41!2s37%20KG%20175%20St%2C%20Kigali!5e1!3m2!1sen!2srw!4v1716304876047!5m2!1sen!2srw"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
