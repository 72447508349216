import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../utils/constants'
import { CheckmarkSquare02Icon, SquareIcon, Loading02Icon, Cancel01Icon } from "hugeicons-react"

const NewSubscriptionForm = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [phone_number, setPhoneNumber] = useState('')
    const [isChecked, setIsChecked] = useState(false)
    const [error, setError] = useState("")
    const [isSuccess, setIsSuccess] = useState(null)

    const handleSubmit = async (e) => {
        if (!email || !phone_number) {
            setError('All fields are required')
            return
        }

        if (!isChecked) {
            setError('Please agree to the terms and conditions')
            return
        }

        setIsLoading(true)

        try {
            const subscriptionData = {
                "email": email,
                "phone_number": phone_number,
                "accepted": isChecked
            }
            const response = await axios.post(`${apiUrl}/subscription/new/`, subscriptionData)
            if (response.status === 201) {
                setIsLoading(false)
                setError('')
                setEmail('')
                setPhoneNumber('')
                setIsChecked(false)
                setIsSuccess(true)

            }
        } catch (error) {
            setIsLoading(false)
            if (error.response) {
                setError(error.response.data.message || error.response.data.error || 'Error while saving your subscription. Try again later')
            } else {
                setError('Unknown error. Try again later')
            }
            setTimeout(() => {
                setError('')
            }, 7000);

            return
        }
    };

    const handleIsChecked = () => {
        setIsChecked(!isChecked);
    }
    return (

        <>
            {
                isSuccess ? <div className="success-card">
                    <h3>Thank you for subscribing!</h3>
                    <p>You have successfully subscribed to our newsletter! Stay tuned for the latest updates </p>
                    <a href='/' className='btn-blue button'>Back to home</a>
                </div>
                    : <div className='subscription-form'>
                        <div className="branding">
                            {/* <img src={'/assets/img/home/logo-blue.png'} alt='Logo' className='logo' /> */}
                            <h1>Subscribe Today</h1>
                        </div>
                        {
                            error && <p className='error-message'>{error}</p>
                        }
                        <form >
                            <div className="field">
                                <label htmlFor='email'>Email:</label>
                                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}
                                    placeholder='Enter your Email:' />
                            </div>
                            <div className="field">

                                <label htmlFor='phoneNumber'>Phone Number:</label>
                                <input type="text" value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder='Enter your Phone Number' />
                            </div>
                            <div onClick={handleIsChecked} className="checkbox">
                                {
                                    isChecked ?
                                        <CheckmarkSquare02Icon />
                                        :
                                        <SquareIcon />
                                }
                                <span>I want to join your email list</span>
                            </div>
                        </form>
                        <button disabled={isLoading || isSuccess} onClick={handleSubmit} type='button' className='btn-blue'>
                            {
                                isLoading ? <Loading02Icon className='loading-icon' size={18} /> : 'Subscribe now'
                            }
                        </button>
                        <div>

                        </div>
                    </div>
            }
        </>
    );
}
export default NewSubscriptionForm;